<template>
  <div class="mall">
    <vue-waterfall-easy :imgsArr="imgsArr" @scrollReachBottom="getData"
      ><div slot-scope="props" class="info">
        <p class="title">{{ props.value.title }}</p>
        <p class="name">作品名称：{{ props.value.name }}</p>
        <p class="price">价格：￥{{ props.value.price }}</p>
      </div></vue-waterfall-easy
    >
  </div>
</template>

<script>
import vueWaterfallEasy from "vue-waterfall-easy";
export default {
  name: "mall",
  components: {
    vueWaterfallEasy,
  },

  data() {
    return {
      imgsArr: [
        {
          src: require("@/assets/szqy/01.png"),
          href: "#/DigitalRights/MallDetail?i=0",
          title: "鸿星尔克",
          name: "橘子男孩联名款#卫衣",
          price: "599.00",
        },
        {
          src: require("@/assets/szqy/02.png"),
          href: "#/DigitalRights/MallDetail?i=1",
          title: "匹克运动",
          name: "太极【6371】篮球文化",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/03.png"),
          href: "#/DigitalRights/MallDetail?i=2",
          title: "匹克运动",
          name: "匹克实验室",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/04.png"),
          href: "#/DigitalRights/MallDetail?i=3",
          title: "中宇厨卫",
          name: "上班狗-厨房打工仔",
          price: "1269.00",
        },
        {
          src: require("@/assets/szqy/05.png"),
          href: "#/DigitalRights/MallDetail?i=4",
          title: "万仟堂",
          name: "平安成套【2021】",
          price: "1680.00",
        },
        {
          src: require("@/assets/szqy/06.png"),
          href: "#/DigitalRights/MallDetail?i=5",
          title: "鸿星尔克",
          name: "吉祥物#萌虎",
          price: "299.00",
        },
        {
          src: require("@/assets/szqy/07.png"),
          href: "#/DigitalRights/MallDetail?i=6",
          title: "匹克运动",
          name: "恶虎仔【纪念版】",
          price: "299.00",
        },
        {
          src: require("@/assets/szqy/08.png"),
          href: "#/DigitalRights/MallDetail?i=7",
          title: "百安消防",
          name: "百特旺宝贝虎",
          price: "3140.00",
        },
        {
          src: require("@/assets/szqy/09.png"),
          href: "#/DigitalRights/MallDetail?i=8",
          title: "华祥苑",
          name: "夜空下的城市",
          price: "299.00",
        },
        {
          src: require("@/assets/szqy/10.png"),
          href: "#/DigitalRights/MallDetail?i=9",
          title: "万仟堂",
          name: "马王堆帛画三【天上篇】",
          price: "1000.00",
        },
        {
          src: require("@/assets/szqy/11.png"),
          href: "#/DigitalRights/MallDetail?i=10",
          title: "百安消防",
          name: "发财虎【2022】",
          price: "299.00",
        },
        {
          src: require("@/assets/szqy/12.png"),
          href: "#/DigitalRights/MallDetail?i=11",
          title: "鸿星尔克",
          name: "节气小精灵",
          price: "299.00",
        },
        {
          src: require("@/assets/szqy/13.png"),
          href: "#/DigitalRights/MallDetail?i=12",
          title: "中国乔丹",
          name: "少年苏东坡",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/14.png"),
          href: "#/DigitalRights/MallDetail?i=13",
          title: "中国乔丹",
          name: "蕉下客",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/15.png"),
          href: "#/DigitalRights/MallDetail?i=14",
          title: "万仟堂",
          name: "佛系千杯",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/16.png"),
          href: "#/DigitalRights/MallDetail?i=15",
          title: "万仟堂",
          name: "蕉下客",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/17.png"),
          href: "#/DigitalRights/MallDetail?i=16",
          title: "鸿星尔克",
          name: "节气小精灵",
          price: "299.00",
        },
        {
          src: require("@/assets/szqy/18.png"),
          href: "#/DigitalRights/MallDetail?i=17",
          title: "鸿星尔克",
          name: "一触肌发",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/19.png"),
          href: "#/DigitalRights/MallDetail?i=18",
          title: "中宇厨卫",
          name: "伟大的仁",
          price: "399.00",
        },
        {
          src: require("@/assets/szqy/20.png"),
          href: "#/DigitalRights/MallDetail?i=19",
          title: "中国乔丹",
          name: "蕉下客",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/21.png"),
          href: "#/DigitalRights/MallDetail?i=20",
          title: "匹克运动",
          name: "匹克实验室",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/22.png"),
          href: "#/DigitalRights/MallDetail?i=21",
          title: "鸿星尔克",
          name: "名牌玩偶 famous toy",
          price: "800.00",
        },
        {
          src: require("@/assets/szqy/23.png"),
          href: "#/DigitalRights/MallDetail?i=22",
          title: "万仟堂",
          name: "飞鸟集-朱鹮",
          price: "1699.00",
        },
        {
          src: require("@/assets/szqy/24.png"),
          href: "#/DigitalRights/MallDetail?i=23",
          title: "PEAK匹克",
          name: "匹克态极3.0",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/25.png"),
          href: "#/DigitalRights/MallDetail?i=24",
          title: "万仟堂",
          name: "蕉下客",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/26.png"),
          href: "#/DigitalRights/MallDetail?i=25",
          title: "PEAK匹克",
          name: "滑雪敦敦",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/27.png"),
          href: "#/DigitalRights/MallDetail?i=26",
          title: "中宇厨卫",
          name: "水浒传",
          price: "1269.00",
        },
        {
          src: require("@/assets/szqy/29.png"),
          href: "#/DigitalRights/MallDetail?i=27",
          title: "匹克运动",
          name: "越野滑雪【因爱而生】",
          price: "369.00",
        },
      ],
    };
  },

  mounted() {},

  methods: {
    getData() {},
  },
};
</script>

<style lang="scss" scoped>
.mall {
  position: absolute; /*必须*/
  top: 80px; /*top必须，大小可控制*/
  bottom: 0; /*bottom必须，一直延申到当前块底部*/
  width: 100%;
  background: #f5f5f5;
  ::v-deep .vue-waterfall-easy-scroll {
    padding-top: 20px;
  }
  .img-box {
    .info {
      padding: 10px 0;
      text-align: center;
      font-size: 12px;
      .title {
        color: #333333;
        font-weight: bold;
        margin-bottom: 8px;
      }
      .name {
        color: #999999;
        margin-bottom: 8px;
      }
      .price {
        color: #999999;
      }
    }
  }
}
</style>