<template>
  <div class="mall-detail">
    <div class="product-box">
      <el-image
        class="productimg"
        style="width: 100px; height: 100px"
        :src="info.img"
        :preview-src-list="info.BigImg"
      >
      </el-image>
      <div class="product-box-right">
        <h3 class="title-name">{{ info.name }}</h3>
        <p class="info">{{ info.version }}</p>
        <div class="labellist">
          <p v-for="(list, index) in info.labellist" :key="index">{{ list }}</p>
        </div>
        <p class="Token">Token ID：{{ info.Token }}</p>
        <div class="price">
          <p>{{ info.price }}</p>
          <span>元</span>
        </div>
        <div class="buy">
          <button @click="centerDialogVisible = true">立即购买</button>
          <div>
            <p><svg-icon icon-class="SGS-1"></svg-icon>匹克官方发行</p>
            <p><svg-icon icon-class="SGS-2"></svg-icon>中国人保财险保证</p>
            <p><svg-icon icon-class="SGS-3"></svg-icon>作品已上链</p>
          </div>
        </div>
      </div>
    </div>
    <div class="writer">
      <p>作者信息</p>
    </div>
    <div class="writerinfo">
      <div class="describe">
        <div>品牌商</div>
        <p>PEAK匹克</p>
      </div>
      <div class="describe">
        <div>品牌商介绍</div>
        <p>
          匹克集团一家以“创国际品牌，做百年企业”为宗旨的企业，创立于1989年，主要从事设计、开发、制造、分销及推广“PEAK匹克”品牌的运动产
          品，包括运动鞋类、服装及配饰，至今已有三十多年历史。匹克以I CAN
          PLAY为品牌logo，最新运动科技系列“态极”系列深受广大运动爱好者 喜爱。
        </p>
      </div>
    </div>
    <div class="writer">
      <p>作品信息</p>
    </div>
    <div class="writerinfo">
      <div class="describe">
        <div>作品名称</div>
        <p>Peak Player</p>
      </div>
      <div class="describe">
        <div>作品简介</div>
        <p>匹克玩家也是巅峰玩家</p>
      </div>
      <div class="describe">
        <div>作品描述</div>
        <p>
          以科技感十足的运动人形象为主要元素，每一个Peak
          Player，都能成为各领域的高级玩家，不断追求卓越，在哪里都能玩得开~
        </p>
      </div>
    </div>
    <div class="writer">
      <p>实体权益信息</p>
    </div>
    <div class="writerinfo">
      <div class="describe">
        <p>1、1双匹克态极3.0PRO运动鞋：有专属认证码（限量编码）</p>
      </div>
      <div class="describe">
        <p>2、1年匹克品牌会员</p>
      </div>
      <div class="describe">
        <p>3、1张线下明星见面会门票</p>
      </div>
      <div class="describe">
        <!-- <p></p> -->
        <ul>
          <li><h3>权益说明：</h3></li>
          <li>
            限量的数字艺术品作价1111元，限量编码克态极3.0PRO运动鞋400元，会员权益作价77元，明星见面会门票300元（一手权益评估，价格根据交易情况有所浮动）
          </li>
        </ul>
      </div>
    </div>
    <div class="writer">
      <p>历史交易价格</p>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="date" label="日期" width="300"> </el-table-column>
      <el-table-column prop="plateNumber" label="版号" width="300">
      </el-table-column>
      <el-table-column prop="price" label="价格" width="300"></el-table-column>
      <el-table-column prop="owner" label="拥有者" width="300">
      </el-table-column>
    </el-table>
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="570px"
      center
    >
      <p>购买功能暂未开放，请前往手机端购买</p>
      <img src="@/assets/szqy/QRcode.png" alt="" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "mall-detail",

  data() {
    return {
      centerDialogVisible: false,

      info: {
        img: "",
        BigImg: [],
        name: "",
        version: "数字运动系列 版本号01/1000",
        labellist: ["数字艺术", "数字艺术家"],
        Token: "93970519746178013927164737099356",
        price: "",
      },
      tableData: [
        {
          date: "2022-02-22",
          plateNumber: "0/1",
          price: "￥1888.00",
          owner: "迟日春风",
        },
        {
          date: "2022-02-21",
          plateNumber: "0/1",
          price: "￥1888.00",
          owner: "tanding818",
        },
        {
          date: "2022-02-21",
          plateNumber: "0/1",
          price: "￥1888.00",
          owner: "寒峰",
        },
        {
          date: "2022-02-21",
          plateNumber: "0/1",
          price: "￥1888.00",
          owner: "Hi bobo",
        },
      ],

      imgsArr: [
        {
          src: require("@/assets/szqy/01.png"),
          href: "#/DigitalRights/MallDetail?i=0",
          title: "鸿星尔克",
          name: "橘子男孩联名款#卫衣",
          price: "599.00",
        },
        {
          src: require("@/assets/szqy/02.png"),
          href: "#/DigitalRights/MallDetail?i=1",
          title: "匹克运动",
          name: "太极【6371】篮球文化",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/03.png"),
          href: "#/DigitalRights/MallDetail?i=2",
          title: "匹克运动",
          name: "匹克实验室",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/04.png"),
          href: "#/DigitalRights/MallDetail?i=3",
          title: "中宇厨卫",
          name: "上班狗-厨房打工仔",
          price: "1269.00",
        },
        {
          src: require("@/assets/szqy/05.png"),
          href: "#/DigitalRights/MallDetail?i=4",
          title: "万仟堂",
          name: "平安成套【2021】",
          price: "1680.00",
        },
        {
          src: require("@/assets/szqy/06.png"),
          href: "#/DigitalRights/MallDetail?i=5",
          title: "鸿星尔克",
          name: "吉祥物#萌虎",
          price: "299.00",
        },
        {
          src: require("@/assets/szqy/07.png"),
          href: "#/DigitalRights/MallDetail?i=6",
          title: "匹克运动",
          name: "恶虎仔【纪念版】",
          price: "299.00",
        },
        {
          src: require("@/assets/szqy/08.png"),
          href: "#/DigitalRights/MallDetail?i=7",
          title: "百安消防",
          name: "百特旺宝贝虎",
          price: "3140.00",
        },
        {
          src: require("@/assets/szqy/09.png"),
          href: "#/DigitalRights/MallDetail?i=8",
          title: "华祥苑",
          name: "夜空下的城市",
          price: "299.00",
        },
        {
          src: require("@/assets/szqy/10.png"),
          href: "#/DigitalRights/MallDetail?i=9",
          title: "万仟堂",
          name: "马王堆帛画三【天上篇】",
          price: "1000.00",
        },
        {
          src: require("@/assets/szqy/11.png"),
          href: "#/DigitalRights/MallDetail?i=10",
          title: "百安消防",
          name: "发财虎【2022】",
          price: "299.00",
        },
        {
          src: require("@/assets/szqy/12.png"),
          href: "#/DigitalRights/MallDetail?i=11",
          title: "鸿星尔克",
          name: "节气小精灵",
          price: "299.00",
        },
        {
          src: require("@/assets/szqy/13.png"),
          href: "#/DigitalRights/MallDetail?i=12",
          title: "中国乔丹",
          name: "少年苏东坡",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/14.png"),
          href: "#/DigitalRights/MallDetail?i=13",
          title: "中国乔丹",
          name: "蕉下客",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/15.png"),
          href: "#/DigitalRights/MallDetail?i=14",
          title: "万仟堂",
          name: "佛系千杯",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/16.png"),
          href: "#/DigitalRights/MallDetail?i=15",
          title: "万仟堂",
          name: "蕉下客",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/17.png"),
          href: "#/DigitalRights/MallDetail?i=16",
          title: "鸿星尔克",
          name: "节气小精灵",
          price: "299.00",
        },
        {
          src: require("@/assets/szqy/18.png"),
          href: "#/DigitalRights/MallDetail?i=17",
          title: "鸿星尔克",
          name: "一触肌发",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/19.png"),
          href: "#/DigitalRights/MallDetail?i=18",
          title: "中宇厨卫",
          name: "伟大的仁",
          price: "399.00",
        },
        {
          src: require("@/assets/szqy/20.png"),
          href: "#/DigitalRights/MallDetail?i=19",
          title: "中国乔丹",
          name: "蕉下客",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/21.png"),
          href: "#/DigitalRights/MallDetail?i=20",
          title: "匹克运动",
          name: "匹克实验室",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/22.png"),
          href: "#/DigitalRights/MallDetail?i=21",
          title: "鸿星尔克",
          name: "名牌玩偶 famous toy",
          price: "800.00",
        },
        {
          src: require("@/assets/szqy/23.png"),
          href: "#/DigitalRights/MallDetail?i=22",
          title: "万仟堂",
          name: "飞鸟集-朱鹮",
          price: "1699.00",
        },
        {
          src: require("@/assets/szqy/24.png"),
          href: "#/DigitalRights/MallDetail?i=23",
          title: "PEAK匹克",
          name: "匹克态极3.0",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/25.png"),
          href: "#/DigitalRights/MallDetail?i=24",
          title: "万仟堂",
          name: "蕉下客",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/26.png"),
          href: "#/DigitalRights/MallDetail?i=25",
          title: "PEAK匹克",
          name: "滑雪敦敦",
          price: "369.00",
        },
        {
          src: require("@/assets/szqy/27.png"),
          href: "#/DigitalRights/MallDetail?i=26",
          title: "中宇厨卫",
          name: "水浒传",
          price: "1269.00",
        },
        {
          src: require("@/assets/szqy/29.png"),
          href: "#/DigitalRights/MallDetail?i=27",
          title: "匹克运动",
          name: "越野滑雪【因爱而生】",
          price: "369.00",
        },
      ],
    };
  },

  created() {
    let i = this.$route.query.i;
    let Arr = this.imgsArr;
    this.info.img = Arr[i].src;
    this.info.BigImg.push(Arr[i].src);
    this.info.name = Arr[i].name;
    this.info.price = Arr[i].price;
    for (const list of this.tableData) {
      list.price = "￥" + this.info.price;
    }
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.mall-detail {
  width: 1200px;
  padding: 40px 0 100px 0;
  .product-box {
    display: flex;
    justify-content: space-between;
    .productimg {
      width: 560px !important;
      height: 560px !important;
      margin-right: 40px;
    }
    .product-box-right {
      flex: 1;
      .title-name {
        height: 32px;
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 16px;
      }
      .info {
        height: 22px;
        font-size: 16px;
        color: #666666;
        margin-bottom: 30px;
      }
      .labellist {
        display: flex;
        margin-bottom: 30px;
        p {
          font-size: 14px;
          color: #333333;
          height: 28px;
          line-height: 26px;
          border: 1px solid #333333;
          border-radius: 2px;
          padding: 0 17px;
          margin-right: 16px;
        }
        p:last-child {
          margin-right: 0;
        }
      }
      .Token {
        font-size: 16px;
        height: 21px;
        color: #666666;
        margin-bottom: 30px;
      }
      .price {
        display: flex;
        color: #fe3132;
        margin-bottom: 30px;
        p {
          height: 55px;
          font-size: 42px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 55px;
        }
        span {
          font-size: 22px;
          height: 55px;
          line-height: 65px;
        }
      }
      .buy {
        display: flex;
        justify-content: space-between;
        button {
          width: 190px;
          height: 62px;
          background: var(--subjectColor);
          border-radius: 31px;
          border: none;
          cursor: pointer;
          font-size: 22px;
          font-weight: 400;
          color: #ffffff;
          letter-spacing: 2px;
        }
        div {
          display: flex;
          align-items: center;
          p {
            font-size: 12px;
            color: #666666;
            opacity: 1;
            margin-left: 16px;
            .svg-icon {
              margin-right: 2px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .writer {
    display: flex;
    border-bottom: 1px solid #c8c8c8;
    margin: 69px 0 25px;
    justify-content: space-between;
    align-content: center;
    p {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 26px;
      color: #333333;
      padding: 10px 16px;
      letter-spacing: 2px;
      border: 1px solid #c8c8c8;
      border-bottom: none;
      border-left: 4px solid var(--subjectColor);
    }
  }
  .writerinfo {
    .describe {
      display: flex;
      padding: 12px 0;
      font-size: 16px;
      font-family: PingFang SC;
      div {
        width: 80px;
        margin-right: 30px;
        color: #888888;
      }
      p {
        width: 1090px;
        color: #333333;
      }
      ul {
        li {
          margin-bottom: 7px;
          h3 {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
  }
}
::v-deep .el-dialog__wrapper {
  .el-dialog {
    margin-top: 22vh !important;
  }
  .el-dialog__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin-bottom: 40px;
    }
  }
  .el-dialog__footer {
    .el-button--primary {
      background-color: var(--subjectColor);
      border-color: var(--subjectColor);
    }
  }
}
</style>